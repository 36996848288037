import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle} from '../assets/styles/MainStyle';
import {View, Image } from 'react-native';
import loading from '../assets/images/loading.gif';
export default class Loading extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
        }
    }

    render() {

        return (<View style={{zIndex: 4000, ...this.state.styles.container, backgroundColor: 'rgba(1,1,1,0.3)', width: '100%', height: '100%', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} ><Image style={{width: 48, height: 48}} source={loading}></Image></View>);
    }
}
