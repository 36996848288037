import AsyncStorage from '@react-native-async-storage/async-storage';

    export const setData  = async (key, value) => {
        try {
          await AsyncStorage.setItem(
            key,
            value
          );
        } catch (error) {
          console.error(error)
        }
      };
    
    export const getData  = async (key) =>  {
      try {
        const value = await AsyncStorage.getItem(key);
        return value;
      } catch (error) {
        // Error retrieving data
        console.error(error)
      }
  }


  export const removeData  = async (key) =>  {
    try {
      await AsyncStorage.removeItem(key);
    } catch (error) {
      // Error retrieving data
      console.error(error)
    }
}


  export const clearQrcode  = async () =>  {
    try {
      const arrKeys = await AsyncStorage.getAllKeys();
      arrKeys.map(key => {
        if(key.includes('qrcode')){
          removeData(key)
        }
      })
    } catch (error) {
      // Error retrieving data
      console.error(error)
    }
}
