import * as React from 'react';
import {NavigateParams} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { checkSerieAvaliable, getBanner } from '../services/ApiServices';
import { View, TouchableOpacity, Image, ScrollView, Text, Linking} from 'react-native';
import { setData, getData } from '../services/StorageService';
import skeleton from '../assets/images/skeleton.gif';
import apkdownload from '../assets/images/icons/apkdownload.png';
import { Base64 } from 'js-base64';
import TopBar from '../components/TopBar';
import Loading from '../components/Loading';
export default class MainScreen extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            cfBanner: skeleton,
            sfBanner : skeleton,
            fdBanner: skeleton,
            isLoading: false
        }
        
        // this.getCFBanner();
        this.getSFBanner();
        this.selectSystemSF();


    }

    openURL = async (url) => {
        const supported = await Linking.canOpenURL(url);
        if (supported) {
          await Linking.openURL(url);
        } else {
          Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    componentDidMount(){
        this. capturaParametrosUrlShareLink();
    }

    capturaParametrosUrlShareLink() {
        getData("sf_system_auth").then(res => {
                var url = window.location.href;
            
                var res = url.includes("?")? url.split('?') : [];
                
                if(res.length > 0){

                    if(res[1].includes("seller=")){
                        this.setState({redirect : {status: true, value: 'ShareLinkSingUpScreen'}});
                    }
                    else{
   
                        this.capturaParametrosUrl();
                    }
                }
        })
      }


    capturaParametrosUrl() {
        var url = window.location.href;
    
        var res = url.includes("?")? url.split('?') : [];
        
        if(res.length > 0){
            var result = Base64.decode(res[1].replace("qrcode=",""));

            checkSerieAvaliable(JSON.parse(result).currentSystem == "sf"? "https://www.sorteflash.com:5000/api/v1/app" : "https://www.cartelaflash.com:5000/api/v1/app", JSON.parse(result).serieId).then(res => {
  
                if(res == true){
                    setData("current_cartela_qrcode_data", result);
                    this.setState({redirect : {status: true, value: 'ClientValidateOrder'}});
                }
            })

        }

      }


    getSFBanner(){
        getBanner("https://www.sorteflash.com:5000/api/v1/app").then(res => this.setState({sfBanner : res ? {uri : res.banner} : skeleton}))
    }


    selectSystemSF(){
        setData("current_system_color", '#00DFA2');
        setData("current_system", "sf");
        setData("current_url", "https://www.sorteflash.com:5000/api/v1/app");
        this.setState({redirect : {status: true, value: 'LoginScreen'}});
    }

    render() {
        return (
            <View>
                <TopBar navigation={this.props.navigation}  isLoading={this.state.isLoading} >
                    <View style={this.state.styles.viewPort}>
                        {this.state.redirect.status? <NavigateParams navigation={this.props.navigation} screen={this.state.redirect.value} params={{MSG: 'OK'}}></NavigateParams> : <Text></Text>}
                        <View style={this.state.styles.container}>

                            <View style={{height:40, ...this.state.styles.row, ...this.state.styles.bgLight, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                                <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cDark}}>Clique na imagem e escolha suas cartelas</Text>
                            </View>
                        <ScrollView>
                            <View style={{...this.state.styles.container, padding : 6}}>
                                <TouchableOpacity style={this.state.styles.mt8} onPress={this.selectSystemSF.bind(this)}>
                                    <Image style={{width: '100%', height: window.screen? window.screen.width > 480? 480 : 360 : 360 , ...this.state.styles.rounded }} source={this.state.sfBanner}/>
                                </TouchableOpacity>
                            </View>
                        </ScrollView>
                        </View>
                    </View>
                </TopBar>
            </View>

        );
    }
}
