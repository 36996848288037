import { StyleSheet} from 'react-native';
export const MainStyle = () => {
    const theme = {
        bgColor: '',
        fontBaseSize: 10,
        componentHeight: 40,
        border_width : 2,
        border_radius : 2,
        primary : '#0079FF',
        secondary: '#a7a7c1',
        danger: '#FF0060',
        warning: '#F6FA70',
        success: '#00DFA2',
        light: 'white',
        dark: '#15162a',
        purple : '#701ca8'
    }

    return StyleSheet.create({
    viewPort : { 
      flex: 1, 
      height: '100%', 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'center'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      height: '100%',
      width: window.screen? window.screen.width > 480? 480 : '100%' : '100%' 
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    justifyCenter: { justifyContent: 'center'},
    alignCenter: {alignItems: 'center'},
    absolute: {position: 'absolute'},
    componentHeight: {height: theme.componentHeight},
    w100:{width: '100%'},
    w90:{width: '90%'},
    w50: {width: '50%'},
    w25: {width: '25%'},
    h100:{height: '100%'},
    h50: {height: '50%'},
    h25: {height: '25%'},
    p6: {padding: 6},
    p8: {padding: 8},
    p16: {padding: 16},
    p32: {padding: 32},
    p64: {padding: 64},
    pt8: {paddingTop: 8},
    pt16: {paddingTop: 16},
    pt32: {paddingTop: 32},
    pt64: {paddingTop: 64},
    pl8: {paddingLeft: 8},
    pl16: {paddingLeft: 16},
    pl32: {paddingLeft: 32},
    pl64: {paddingLeft: 64},
    mt8: {marginTop: 8},
    mt16: {marginTop: 16},
    mt32: {marginTop: 32},
    mt64: {marginTop: 64},
    ml8: {marginLeft: 8},
    ml16: {marginLeft: 16},
    ml32: {marginLeft: 32},
    ml64: {marginLeft: 64},
    fs10: {fontSize: theme.fontBaseSize + 4},
    fs14: {fontSize: theme.fontBaseSize + 6},
    fs18: {fontSize: theme.fontBaseSize + 12},
    fs22: {fontSize: theme.fontBaseSize + 16},
    bold: {fontWeight: 'bold'},
    fontDefault: {fontFamily: 'Roboto'},
    rounded: { borderRadius: theme.border_radius},
    bgTransparent: {backgroundColor: 'transparent'},
    cLight: {color: theme.light},
    bgLight: {backgroundColor: theme.light},
    cDark: {color: theme.dark},
    bgDark: {backgroundColor: theme.dark},
    cPrimary: {color: theme.primary},
    bgPrimary: {backgroundColor: theme.primary},
    cSecondary: {color: theme.secondary},
    bgSecondary: {backgroundColor: theme.secondary},
    cDanger: {color: theme.danger},
    bgDanger: {backgroundColor: theme.danger},
    cWarning: {color: theme.warning},
    bgWarning: {backgroundColor: theme.warning},
    cSuccess: {color: theme.success},
    bgSuccess: {backgroundColor: theme.success},
    cPurple: {color: theme.purple},
    bgPurple: {backgroundColor: theme.purple},
    borderPrimary: {borderColor: theme.primary, borderWidth: theme.border_width},
    borderSecondary: {borderColor: theme.secondary, borderWidth: theme.border_width},
    borderDanger: {borderColor: theme.danger, borderWidth: theme.border_width},
    borderWarning: {borderColor: theme.warning, borderWidth: theme.border_width},
    borderSuccess: {borderColor: theme.success, borderWidth: theme.border_width},
    borderLight: {borderColor: theme.light, borderWidth: theme.border_width},
    borderDark: {borderColor: theme.dark, borderWidth: theme.border_width},
    borderBottomPrimary: {borderColor: theme.primary, borderBottomWidth: theme.border_width},
    borderBottomSecondary: {borderColor: theme.secondary, borderBottomWidth: theme.border_width},
    borderBottomDanger: {borderColor: theme.danger, borderBottomWidth: theme.border_width},
    borderBottomWarning: {borderColor: theme.warning, borderBottomWidth: theme.border_width},
    borderBottomSuccess: {borderColor: theme.success, borderBottomWidth: theme.border_width},
    borderBottomLight: {borderColor: theme.light, borderBottomWidth: theme.border_width},
    borderBottomDark: {borderColor: theme.dark, borderBottomWidth: theme.border_width},
    myShadow: {
      borderWidth: 1,
      borderRadius: 6,
      borderColor: 'rgba(1,1,1,0.2)',
      shadowColor: 'rgba(1,1,1,0.2)',
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.4,
      shadowRadius: 6,
      elevation: 1
    }
  });
}