import * as React from 'react';
import { View,TouchableOpacity, Text, ScrollView, TextInput} from 'react-native';
export default class Picker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: {status: false, value: '/'}, 
            arrayLabelValue : [{label: this.props.placeHolder, value : null}, ...this.props.arrayLabelValue],
            currentIndex : 0,
            currentValue : "",
            openModal : false,
            label: ""
            
        }
    }

    selectedValue (value){
       this.state.arrayLabelValue.map((element,index) => {
        if(element.value == value){
            this.setState({currentIndex : index})
            this.props.onValueChange(element.value);
            this.setState({label : element.label})
        }
       }) 

       this.setState({openModal: false})
    }

    calcValue(value){
        if(value.length < this.state.label.length){
           this.setState({arrayLabelValue : [{label: this.props.placeHolder, value : null}, ...this.props.arrayLabelValue]})
        }

        var arrayTemp = [];
        this.setState({label: value})
        for(var i = 0; i < this.props.arrayLabelValue.length; i++){
            if(this.props.arrayLabelValue[i].label.toLowerCase().includes(value.toLowerCase())){
                arrayTemp.push(this.props.arrayLabelValue[i])
            }
        }
        this.setState({arrayLabelValue : arrayTemp})

    }

    render() {

        return (<View style={this.props.style}>
                    <TouchableOpacity onPressIn={() => this.setState({openModal: !this.state.openModal})}>
                        {this.props.isAutoComplete? 
                        
                        <TextInput style={{width: '100%',  height: 40, paddingLeft: 6}} 
                            placeholder='Selecionar'
                            onChangeText={(value) => {this.calcValue(value)}}
                            value={this.state.label}
                            keyboardType='default'
                         ></TextInput>
                        :
                            <View style={{width: '100%', height: 32, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 8}}><Text> {this.state.arrayLabelValue[this.state.currentIndex].label}</Text></View>
                        }


                    {this.state.openModal? 
                    <TouchableOpacity onPressIn={() => this.setState({openModal: !this.state.openModal})} >
                        <ScrollView >
                            <View style={{width: '100%', height: this.state.arrayLabelValue.length * 32, backgroundColor: 'white'}}>
                                {this.state.arrayLabelValue.map((el,index) => {
                                    return <TouchableOpacity key={"index"+index} onPressIn={() => this.selectedValue(el.value)}>
                                            <View style={{zIndex: 3000, backgroundColor: 'white', width: '100%', height: 32, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', borderWidth: 1, borderColor: 'gray', paddingLeft: 8}}><Text>{el.label}</Text></View>
                                        </TouchableOpacity>
                                })}
                            </View>
                        </ScrollView>  
                    </TouchableOpacity>        
                    : <Text></Text>}
                </TouchableOpacity>
        </View>);
    }
}