import * as React from 'react';
import {Navigate} from '../services/Navigator';
import {Text, View, TouchableOpacity, Image} from 'react-native';
import { MainStyle } from '../assets/styles/MainStyle';

export default class ButtonComponent extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            variants: {
                primary: { 
                    body : [ styles.componentHeight ,styles.borderPrimary, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cPrimary, styles.bold]
                },
                secondary: { 
                    body : [ styles.componentHeight ,styles.borderSecondary, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cSecondary, styles.bold]
                },
                danger: { 
                    body : [ styles.componentHeight ,styles.borderDanger, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cDanger, styles.bold]
                },
                warning: { 
                    body : [ styles.componentHeight ,styles.borderWarning, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cWarning, styles.bold]
                },
                success: { 
                    body : [ styles.componentHeight ,styles.borderSuccess, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cSuccess, styles.bold]
                },
                dark: { 
                    body : [ styles.componentHeight ,styles.borderDark, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cDark, styles.bold]
                },
                light: { 
                    body : [ styles.componentHeight ,styles.borderLight, styles.bgTransparent, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cLight, styles.bold]
                },
                primary_filled: { 
                    body : [ styles.componentHeight ,styles.bgPrimary, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cLight, styles.bold]
                },
                secondary_filled: { 
                    body : [ styles.componentHeight ,styles.bgSecondary, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cLight, styles.bold]
                },
                danger_filled: { 
                    body : [ styles.componentHeight ,styles.bgDanger, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cLight, styles.bold]
                },
                warning_filled: { 
                    body : [ styles.componentHeight ,styles.bgWarning, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cDark, styles.bold]
                },
                success_filled: { 
                    body : [ styles.componentHeight ,styles.bgSuccess, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cLight, styles.bold]
                },
                dark_filled: { 
                    body : [ styles.componentHeight ,styles.bgDark, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cLight, styles.bold]
                },
                light_filled: { 
                    body : [ styles.componentHeight ,styles.bgLight, styles.row, styles.justifyCenter, styles.alignCenter , styles.p8, styles.rounded, {width: '100%'}],
                    text : [styles.fs14, styles.fontDefault, styles.cDark, styles.bold]
                }, 
            }   
        }
    }

    render() {
        
        return (
        <TouchableOpacity disabled={this.props.disabled} onPressIn={this.props.action}>
            <View style={this.state.variants[this.props.variant].body}>
                <Text style={this.state.variants[this.props.variant].text}>{this.props.children}</Text>
                {this.props.icon? <Image style={{marginLeft: 16, width: 16, height: 16}} source={this.props.icon}></Image> : ''}
            </View>
        </TouchableOpacity>
        );
    }
}
