import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { navigationRef } from './services/RootNavigation';
import MainScreen from './src/MainScreen'
import LoginScreen from './src/LoginScreen';
import ClientSingUpScreen from './src/ClientSingUpScreen';
import Store from './src/Store';
import Orders from './src/Orders';
import PendingPayment from './src/PendingPayment';
import PixPayment from './src/PixPayment';
import HistoricOrders from './src/HistoricOrders';
import Regulation from './src/Regulation';
import Profile from './src/Profile';
import HistoricWinners from './src/HistoricWinners';
import LoginSellerScreen from './src/LoginSellerScreen';
import StoreSeller from './src/StoreSeller';
import PaymentSeller from './src/PaymentSeller';
import OrdersSeller from './src/OrdersSeller';
import RegulationSeller from './src/RegulationSeller';
import ReceiveOrders from './src/ReceiveOrders';
import SerieSeller from './src/SerieSeller';
import ClientSingUpSellerScreen from './src/ClientSingUpSellerScreen';
import ReportsSeller from './src/ReportsSeller';
import ProfileSeller from './src/ProfileSeller';
import HistoricWinnersSeller from './src/HistoricWinnersSeller';
import ClientsSeller from './src/ClientsSeller';
import ResetPasswordScreen from './src/ResetPasswordScreen';
import SellerSingUpScreen from './src/SellerSingUpScreen';
import PixPaymentSeller from './src/PixPaymentSeller';
import ChooseProfile from './src/ChooseProfile';
import RegulationFree from './src/RegulationFree';
import ClientValidateOrder from './src/ClientValidateOrder';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import GeralWinners from './src/GeralWinners';
import WithdrawSeller from './src/WithdrawSeller';
import ShareLinkSingUpScreen from './src/ShareLinkSingUpScreen';
import { View } from 'react-native-web';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4r_zZxQMvSZhh0L-LrvutDPQ9ZTDDs40",
  authDomain: "sorteflashapp.firebaseapp.com",
  projectId: "sorteflashapp",
  storageBucket: "sorteflashapp.appspot.com",
  messagingSenderId: "249990299342",
  appId: "1:249990299342:web:7d72ca235d9f0fa5e15a9f",
  measurementId: "G-BMCGG4R5MZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//firebase deploy --only hosting
const Stack = createNativeStackNavigator();

export default function App() {

    return (
      <NavigationContainer ref={navigationRef}>
        <Stack.Navigator>
          <Stack.Screen options={{headerShown: false}}  name="MainScreen" component={MainScreen} />
          <Stack.Screen options={{headerShown: false}}  name="ChooseProfile" component={ChooseProfile} />
          <Stack.Screen options={{headerShown: false}}  name="LoginScreen" component={LoginScreen} />
          <Stack.Screen optisons={{headerShown: false}} name="LoginSellerScreen" component={LoginSellerScreen} />
          <Stack.Screen options={{headerShown: false}}  name="ResetPasswordScreen" component={ResetPasswordScreen} />
          <Stack.Screen options={{headerShown: false}}  name="ClientSingUpScreen" component={ClientSingUpScreen} />
          <Stack.Screen options={{headerShown: false}}  name="ClientSingUpSellerScreen" component={ClientSingUpSellerScreen} />
          <Stack.Screen options={{headerShown: false}}  name="Store" component={Store} />
          <Stack.Screen options={{headerShown: false}}  name="StoreSeller" component={StoreSeller} />
          <Stack.Screen options={{headerShown: false}}  name="Orders" component={Orders} />
          <Stack.Screen options={{headerShown: false}}  name="OrdersSeller" component={OrdersSeller} />
          <Stack.Screen options={{headerShown: false}}  name="Profile" component={Profile} />
          <Stack.Screen options={{headerShown: false}}  name="ProfileSeller" component={ProfileSeller} />
          <Stack.Screen options={{headerShown: false}}  name="RegulationFree" component={RegulationFree} />
          <Stack.Screen options={{headerShown: false}}  name="Regulation" component={Regulation} />
          <Stack.Screen options={{headerShown: false}}  name="RegulationSeller" component={RegulationSeller} />
          <Stack.Screen options={{headerShown: false}}  name="HistoricOrders" component={HistoricOrders} />
          <Stack.Screen options={{headerShown: false}}  name="HistoricWinners" component={HistoricWinners} />
          <Stack.Screen options={{headerShown: false}}  name="HistoricWinnersSeller" component={HistoricWinnersSeller} />
          <Stack.Screen options={{headerShown: false}}  name="PendingPayment" component={PendingPayment} />
          <Stack.Screen options={{headerShown: false}}  name="PixPayment" component={PixPayment} />
          <Stack.Screen options={{headerShown: false}}  name="PaymentSeller" component={PaymentSeller} />
          <Stack.Screen options={{headerShown: false}}  name="ReceiveOrders" component={ReceiveOrders} />
          <Stack.Screen options={{headerShown: false}}  name="SerieSeller" component={SerieSeller} />
          <Stack.Screen options={{headerShown: false}}  name="ReportsSeller" component={ReportsSeller} />
          <Stack.Screen options={{headerShown: false}}  name="ClientsSeller" component={ClientsSeller} />
          <Stack.Screen options={{headerShown: false}}  name="SellerSingUpScreen" component={SellerSingUpScreen} />
          <Stack.Screen options={{headerShown: false}}  name="PixPaymentSeller" component={PixPaymentSeller} />
          <Stack.Screen options={{headerShown: false}}  name="ClientValidateOrder" component={ClientValidateOrder} />
          <Stack.Screen options={{headerShown: false}}  name="GeralWinners" component={GeralWinners} />
          <Stack.Screen options={{headerShown: false}}  name="WithdrawSeller" component={WithdrawSeller} />
          <Stack.Screen options={{headerShown: false}}  name="ShareLinkSingUpScreen" component={ShareLinkSingUpScreen} />

        </Stack.Navigator>
      </NavigationContainer>
      );
}