import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,TouchableOpacity, ScrollView, Text, Image, Linking, Alert} from 'react-native';
import cfLogo from '../assets/images/logos/cf_logo.png';
import fdLogo from '../assets/images/logos/fd_logo.png';
import sfLogo from '../assets/images/logos/sf_logo.png';
import menu_icon from '../assets/images/icons/bars-solid.png';
import regulation_icon from '../assets/images/icons/book-open-solid-dark.png';
import winner_icon from '../assets/images/icons/trophy-solid-dark.png';
import { getData, removeData } from '../services/StorageService';
import home_icon from '../assets/images/icons/house-solid-dark.png';
import user_icon from '../assets/images/icons/user-solid-dark.png';
import Loading from '../components/Loading';
export default class TopBar extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: ''},
            styles : styles,
            modalOpened: false,
            currentSystem: "",
            systemColor: 'white',
            currentLogo: sfLogo,
            user: {}
        }
    }
    componentDidMount(){
  
    }

    handleOpenModal(){
      this.setState({modalOpened: !this.state.modalOpened})
    }
   
    render() {
        return (
          <View style={this.state.styles.viewPort}>
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
          <View style={this.state.styles.container}>
            {this.props.isLoading? <Loading></Loading> : <Text></Text>}
            {this.state.modalOpened ? <TouchableOpacity onPress={() => this.handleOpenModal()} style={{...this.state.styles.container, position: 'absolute', zIndex: 3000, width: '100%', height: '100%', backgroundColor: 'rgba(1,1,1,0.3)'}}>
              <View style={{position: 'absolute',zIndex: 3001, top: 32, right: 32,  width: 256, height: 170, backgroundColor: 'white', borderRadius: 6, display: 'flex', flexDirection: 'column'}}>
                <TouchableOpacity onPress={() =>  {window.location.href = "https://www.redesorteflash.com"}} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={home_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Home</Text>
                </TouchableOpacity>  
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'LoginSellerScreen'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={user_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Colaborador</Text>
                </TouchableOpacity>    
                <TouchableOpacity onPress={() => this.setState({redirect : {status: true, value: 'GeralWinners'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={winner_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Ganhadores</Text>
                </TouchableOpacity>  
                <TouchableOpacity onPress={() =>     this.setState({redirect : {status: true, value: 'RegulationFree'}})} style={{width: '100%', height: 40, ...this.state.styles.row, marginLeft: 32, ...this.state.styles.alignCenter }}>
                  <Image source={regulation_icon} style={{width: 18, height: 18}}></Image>
                  <Text style={{...this.state.styles.fs14, ...this.state.styles.bold, ...this.state.styles.cDark, marginLeft: 12}}>Regulamento</Text>
                </TouchableOpacity>      
              </View>
            </TouchableOpacity>
             : <Text></Text>}
            <View style={{height: 40, width: '100%', ...this.state.styles.row, justifyContent: 'space-around' , backgroundColor : '#00DFA2', ...this.state.styles.alignCenter}}>
              <TouchableOpacity >
              </TouchableOpacity>         
              <View style={{width: 'auto', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignContent:  'center'}}>         
              <Image source={this.state.currentLogo}style={{width: 120, height: 28}}></Image>
              </View>
              <TouchableOpacity onPress={() => this.handleOpenModal()}>
                <Image source={menu_icon} style={{width: 18, height: 18}} ></Image> 
              </TouchableOpacity>   
            </View>
            <View style={this.state.styles.container}>
              {this.props.children}
            </View>
          </View>
          </View>
        )
    }
}
