//import { Link, useRouter, useSegments } from 'expo-router';
import { setData } from './StorageService';
import { Link  } from '@react-navigation/native';


export function Navigate({navigation,screen}){
   // const router = useHistory();
    if(screen == ''){
        //router.replace("/");
        window.location.href = "";
    }
    else if(screen == 'PendingPayment' || screen == 'Store'  || screen == 'StoreSeller'){
        //router.replace("/");
        navigation.popToTop();
        navigation.navigate(screen);
    }
    else{
        //router.push("/"+screen);
        navigation.popToTop();
        navigation.push(screen);
    }
    return (<Link to={"/"+ screen}></Link>);
};

export function NavigateParams({navigation ,screen, params}){
    setData("props", JSON.stringify(params));
    //const router = useHistory();
    //router.push("/"+screen);
    navigation.popToTop();
    navigation.push(screen);
    return (<Link to={"/"+ screen}></Link>);
};