import * as React from 'react';
import {Navigate} from '../services/Navigator';
import { MainStyle } from '../assets/styles/MainStyle';
import { View,Image, Text, Alert, TouchableOpacity} from 'react-native';
import Picker from '../components/Picker';
import NavBarSeller from '../components/NavBarSeller';
import { getSellerOrders, getSellerReports, getSellerComissoesUsusario, getSellerOrdersHistoric, getHistorics, getDesconto , createSellerCreateComission } from '../services/ApiServices';
import { getData } from '../services/StorageService';
import pix_icon from '../assets/images/icons/pix.png';
import moment from "moment";
import loading from '../assets/images/loading.gif';
export default class WithdrawSeller extends React.Component {
    constructor(props) {
        super(props);
        const styles = MainStyle();
        this.state = {
            redirect: {status: false, value: '/'},
            styles : styles,
            user: {},
            systemColor: 'white',
            currentUrl: "",
            currentSystem: "",
            comissoes: [],
            report: {cartelas: 0, comissao: 0},
            orders: [],
            selectedValue: null,
            selectedHistoric: null,
            historics: [],
            isLoading: false,
            comissaoPrevia: null,
            baseHistoric: {dataInicial: new Date(), dataFinal: new Date()}
        }
    }

    componentDidMount(){
        this.setState({isLoading : true});
        getData("current_system_color").then(res => this.setState({systemColor : res}))
        this.initializeDataState().then(res => {
            this.setState({isLoading : false});
            getHistorics(res.url).then(res => this.setState({historics : Array.isArray(res)? res : []}))
            getSellerComissoesUsusario(res.url, res.user._id).then(res => {
                const comissoes = Array.isArray(res)? res.reverse() : []
                const  baseHistoric =  {dataInicial : comissoes.length > 0? comissoes[0].periodo.dataFinal : new Date(), dataFinal: new Date()}
                this.setState({comissoes : comissoes, baseHistoric: baseHistoric, selectedHistoric: baseHistoric });
                this.getIntervalOrders(baseHistoric)
            })
        })
    }

    async initializeDataState(){
        let url = await getData("current_url");
        let sys = await getData("current_system");
        this.setState({currentUrl: url, currentSystem: sys})
            
        let user = await getData(sys+"_user");
        this.setState({user : JSON.parse(user)});
        return {url, user: JSON.parse(user)};

    }

    formatDate(data) {
        return moment(data).add(0, 'days').format('DD-MM-YYYY')
    }


    solicitarComissao(){
        if(this.state.selectedHistoric == null){
            alert("Você deve selecionar um historico antes!");
            Alert.alert("Você deve selecionar um historico antes!");
        }
        else if(this.state.comissoes.some(comissao => comissao.periodo.dataInicial == this.state.selectedHistoric.dataInicial)){
            alert("Você já fez uma solicitação para esse periodo!");
            Alert.alert("Você já fez uma solicitação para esse periodo!");
        }
        else if(this.state.report.comissao <= 0){
            alert("Não há vendas nesse periodo!");
            Alert.alert("Não há vendas nesse periodo!");
        }
        else{
            createSellerCreateComission(this.state.currentUrl,  this.state.user._id, this.state.selectedHistoric, this.state.report.comissao).then(res => {
                this.state.comissoes= [res, ...this.state.comissoes];
                this.setState({comissoes: this.state.comissoes})
            });
            alert("Solicitação realizada com sucesso! AGUARDE A BANCA");
            Alert.alert("Solicitação realizada com sucesso! AGUARDE A BANCA");
        }
    }

    getIntervalOrders(historico){

        getSellerOrdersHistoric(this.state.currentUrl, this.state.user._id, historico).then(res => {
            this.setState({orders : Array.isArray(res)? res : []});
            this.generateReport(res);
            if(res.length == 0){
                this.setState({selectedHistoric: null});
                alert("Não há vendas nesse periodo!");
                Alert.alert("Não há vendas nesse periodo!");
            }
        })
    }

    generateReport(orders){
        if(orders.length > 0){
            let cartelas = 0;
            orders.map(order => {
                if(order.validado && !order.isCredit && order.isPixPayment){
                    cartelas += order.cartelas.length;
                }

            })
        this.forceUpdate()
        this.state.comissaoPrevia = this.state.comissaoPrevia == null || this.state.comissaoPrevia == 0 ? cartelas * this.state.user.comissao :  this.state.comissaoPrevia;
        console.log(this.state.comissaoPrevia == null || this.state.comissaoPrevia == 0 ? cartelas * this.state.user.comissao :  this.state.comissaoPrevia)
        this.setState({report : {
            cartelas : cartelas, 
            comissao: cartelas * this.state.user.comissao,
            comissaoPrevia: this.state.comissaoPrevia == null || this.state.comissaoPrevia == 0 ? cartelas * this.state.user.comissao :  this.state.comissaoPrevia
        }})
    }
    }

    render() {
        return (
            <NavBarSeller navigation={this.props.navigation} isLoading={this.state.isLoading} >
            {this.state.redirect.status? <Navigate navigation={this.props.navigation} screen={this.state.redirect.value}></Navigate> : <Text></Text>}
                <View style={{height:40, ...this.state.styles.row, backgroundColor : this.state.systemColor, ...this.state.styles.justifyCenter, ...this.state.styles.alignCenter}}>
                    <Text  style={{...this.state.styles.bold, ...this.state.styles.fs14, ...this.state.styles.cLight}}>Saque de comissão</Text>
                    {/* <Image source={chart_icon} style={{width : 22, height: 22, marginLeft: 12}} />  */}
                </View>
                <View style={{...this.state.styles.row, ...this.state.styles.alignCenter, justifyContent: 'space-around', ...this.state.styles.mt8}}>
                        <View style={{width: '60%' , borderWidth: 1, borderColor: 'gray', borderRadius: '4px'  }}>
                            <View style={{display: 'flex', flexDirection: 'row'}}>
                                <Text  style={{fontWeight: 'bold'}}>Comissão: </Text> 
                                <Text>R$ {parseFloat(this.state.comissaoPrevia?? 0).toFixed(2)} </Text> 
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row'}}>   
                                <Text  style={{fontWeight: 'bold'}}>Periodo: </Text>
                                <Text >{this.formatDate(this.state.baseHistoric.dataInicial)} ➔ {this.formatDate(this.state.baseHistoric.dataFinal)} </Text>  
                            </View>
                        </View>
                    <View style={{width: 128}}>
                        <TouchableOpacity disabled={this.state.selectedHistoric != null && this.state.report.comissao <= 0} onPress={() => this.solicitarComissao()} style={{display: 'flex', flexDirection: 'row', width: '100%', borderWidth: 1, borderColor: 'gray', borderRadius: '6px'}}>
                            {this.state.report.comissao >= 0 || this.state.selectedHistoric == null?
                                <Image source={pix_icon} style={{width : 22, height: 22, marginLeft: 12, marginTop: 6}} />
                            :
                                <Image source={loading} style={{width : 22, height: 22, marginLeft: 12, marginTop: 6}} />
                            }
                            <Text style={{fontWeight: "bold", marginLeft: 12}}>Solicitar saque</Text>

                        </TouchableOpacity>
                    </View>
                </View>

                <View style={{...this.state.styles.col}}>
                    <View style={{height:40, ...this.state.styles.row, width: '100%'}}>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Solicitado</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Comissão</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Periodo entre</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row,  ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>Status</Text>
                        </View>
                    </View>

                    {this.state.comissoes.map(comissao => 
                    <View style={{height:40, ...this.state.styles.row, ...this.state.styles.myShadow, width: '100%'}}>

                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>{`${this.formatDate(comissao.data)}` }</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>R$: {comissao.valor}</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', display: 'flex', flexDirection: 'column', paddingLeft: 6}}>
                            <Text style={{...this.state.styles.bold}}>{`${this.formatDate(comissao.periodo.dataInicial)}`}</Text>
                            <Text style={{...this.state.styles.bold}}>{`${this.formatDate(comissao.periodo.dataFinal)}`}</Text>
                        </View>
                        <View style={{height: '100%', width: '25%', ...this.state.styles.row, ...this.state.styles.myShadow, ...this.state.styles.alignCenter, ...this.state.styles.justifyCenter}}>
                            <Text style={{...this.state.styles.bold}}>{comissao.status}</Text>
                        </View>
                    </View> 
                    )}

                </View>

                
            </NavBarSeller>
            )
    }
}
